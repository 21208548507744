<template>
<div class="bg bg--start">
  <header-block cls="header__menu--start"/>
  <div class="container">
    <div class="start">
      <h1 class="title">
        Тест «5 языков любви»
      </h1>
      <p class="description description--start">
        Определите свой язык любви и научитесь лучше понимать себя и партнера.
      </p>
      <div class="txt__block txt__block--start">
        <p class="txt">
          В каждом пункте есть два утверждения, из которых нужно выбрать одно.<br>
          Если оба варианта подходят, выберите тот,
          который вам больше нравится именно в этой паре утверждений.
        </p>
        <p class="txt">
          В тесте встречаются такие выражение, как «тот, кто дорог мне» или «близкие».
          Когда мы думаем о любви, конечно, сразу же возникает ассоциации с романтическими
          отношениями. Однако мы испытываем любовь и привязанность не только в контексте
          влюбленности. Заполняя тест, подумайте, также о близких вам людям: о парне / девушке,
          родителях, коллегах, друзьях и т.д.
        </p>
        <p class="txt">
          Заполняйте тест в спокойной обстановке, когда вас
          не подгоняет время. В конце текста вы увидите подсчет
          баллов по каждому из Языков любви (каждый соотвествует
          одной из букв A, B, С, D или E). Визуальная схожесть вопросов
          в тесте обоснована алгоритмом психологического тестирования.
        </p>
      </div>
      <router-link to="/test" class="button">
        Начать
      </router-link>
      <img src="@/assets/images/love/start.png"
        srcset="@/assets/images/love/start@2x.png 2x" alt="img" class="start__img">
    </div>
  </div>
</div>
</template>

<script>
import HeaderBlock from '@/components/Header.vue';
import auth from '@/mixins/auth';

export default {
  mixins: [auth],
  components: {
    HeaderBlock,
  },
  name: 'start-couple',
};
</script>
