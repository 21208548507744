<template>
<div class='bg'>
  <header-block cls="header__menu--step" />
  <div class="container">
    <div class="step-block step-block--1">
      <div class="step-line__block">
        <div class="step-line">
          <div class="step-line--2"
          :style="lineStyle"
        ></div>
        </div>
        <div class="step-line__nums">
          <div
            class="step-line__num"
            :class="{'step-line__num--active' : n <= current}"
            v-for="n in 30"
            :key="n"
          >
            {{ n }}
          </div>
        </div>
      </div>
      <h1 class="title title--step">
        {{ current }}
      </h1>
      <form class="step-form" @submit.prevent="next">
        <div class="step-answers" v-for="(question, index) in questions"
          :key="index" v-show="index === current - 1">
          <div class="step-answer" v-for="(a, i) in question" :key="i">
            <label
              :for="'answer-' + index + '-' + i"
              class="step-answer__label">
              <input
                v-model="answer[index]"
                required
                :id="'answer-' + index + '-' + i"
                type="radio" class="step-answer__radio" name="answer"
                :value="a.t"
              />
              <div class="step-answer__circle"></div>
              <div class="step-answer__num">{{ a.t }}</div>
              <div class="step-answer__txt">
                {{ a.a }}
              </div>
            </label>
          </div>
        </div>
        <div class="button__block">
          <button class="button" type="submit">
            Дальше
          </button>
        </div>
      </form>
      <img :src="img" alt="img" class="step-block__img"
        :class="['step-block__img--' + current, 'step-block__img--' + type]"
      >
    </div>
  </div>
</div>
</template>

<script>
import HeaderBlock from '@/components/Header.vue';
import auth from '@/mixins/auth';
import img1 from '@/assets/images/love/q1.png';
import img2 from '@/assets/images/love/q1_2.png';
import img3 from '@/assets/images/love/q1_3.png';
import img4 from '@/assets/images/love/q1_4.png';
import img5 from '@/assets/images/love/q1_5.png';
import img6 from '@/assets/images/love/q1_6.png';
import img7 from '@/assets/images/love/q1_7.png';
import img8 from '@/assets/images/love/q1_8.png';
import img9 from '@/assets/images/love/q1_9.png';
import img10 from '@/assets/images/love/q1_10.png';
import img11 from '@/assets/images/love/q1_11.png';
import img12 from '@/assets/images/love/q1_12.png';
import img13 from '@/assets/images/love/q1_13.png';
import img14 from '@/assets/images/love/q1_14.png';
import img15 from '@/assets/images/love/q1_15.png';
import img16 from '@/assets/images/love/q1_16.png';
import img17 from '@/assets/images/love/q1_17.png';
import img18 from '@/assets/images/love/q1_18.png';
import img19 from '@/assets/images/love/q1_19.png';
import img20 from '@/assets/images/love/q1_20.png';
import img21 from '@/assets/images/love/q1_21.png';
import img22 from '@/assets/images/love/q1_22.png';
import img23 from '@/assets/images/love/q1_23.png';
import img24 from '@/assets/images/love/q1_24.png';
import img25 from '@/assets/images/love/q1_25.png';
import img26 from '@/assets/images/love/q1_26.png';
import img27 from '@/assets/images/love/q1_27.png';
import img28 from '@/assets/images/love/q1_28.png';
import img29 from '@/assets/images/love/q1_29.png';
import img30 from '@/assets/images/love/q1_30.png';

export default {
  mixins: [auth],
  components: {
    HeaderBlock,
  },
  name: 'test-couple',
  data() {
    return {
      current: 1,
      yes: [],
      no: [],
      dk: [],
      answer: {},
      // eslint-disable-next-line
      questions: [
        [{
          t: 'A',
          a: 'Мне нравится слышать слова поощрения',
        }, {
          t: 'E',
          a: 'Мне нравится, когда меня обнимают',
        }],
        [{
          t: 'B',
          a: 'Мне нравится проводить время наедине с дорогими мне людьми',
        }, {
          t: 'D',
          a: 'Я чувствую себя любимым, когда мне оказывают практическую помощь',
        }],
        [{
          t: 'C',
          a: 'Мне нравится получать подарки',
        }, {
          t: 'B',
          a: 'Мне нравится подолгу беседовать с друзьями и близкими',
        }],
        [{
          t: 'D',
          a: 'Я чувствую себя любимым, когда кто-то помогает мне',
        }, {
          t: 'E',
          a: 'Чувствую себя любимым, когда кто-то ласково прикасается ко мне',
        }],
        [{
          t: 'D',
          a: 'Я чувствую себя любимым, когда тот человек, который любит меня или симпатизирует мне, преподносит мне подарок',
        }, {
          t: 'E',
          a: 'Я чувствую себя любимым, когда тот человек, который любит меня или симпатизирует мне, обнимает меня',
        }],
        [{
          t: 'B',
          a: 'Мне нравится гулять с друзьями и близкими.',
        }, {
          t: 'E',
          a: 'Я люблю давать «пять» или держать за руку того, кто мне дорог',
        }],
        [{
          t: 'C',
          a: 'Видимые символы любви (подарки) очень важны для меня',
        }, {
          t: 'A',
          a: 'Я чувствую себя любимым, когда другие поощряют меня соответствующими словами',
        }],
        [{
          t: 'E',
          a: 'Мне нравится находиться рядом с теми, с кем мне приятно проводить время',
        }, {
          t: 'A',
          a: 'Мне нравится, когда кто-то говорит, что я симпатичный / красивый',
        }],
        [{
          t: 'B',
          a: 'Мне приятно проводить время с друзьями и близкими',
        }, {
          t: 'C',
          a: 'Мне нравится принимать подарки от друзей и близких',
        }],
        [{
          t: 'A',
          a: 'Слова принятия очень важны для меня',
        }, {
          t: 'D',
          a: 'Если кто-то помогает мне, значит, он любит меня',
        }],
        [{
          t: 'B',
          a: 'Мне нравится находиться рядом с друзьями и близкими',
        }, {
          t: 'A',
          a: 'Мне нравится слышать в свой адрес добрые слова',
        }],
        [{
          t: 'D',
          a: 'Меня больше убеждают поступки человека, нежели слова',
        }, {
          t: 'E',
          a: 'Объятия помогают мне чувствовать себя ценным и любимым',
        }],
        [{
          t: 'A',
          a: 'Мне нравится похвала, и, напротив, я избегаю критики',
        }, {
          t: 'C',
          a: 'Я отдаю предпочтение множеству маленьких подарков перед одним большим',
        }],
        [{
          t: 'B',
          a: 'Я чувствую особую близость с людьми тогда, когда мы беседуем или что-то делаем вместе',
        }, {
          t: 'E',
          a: 'Я чувствую большую близость с друзьями и близкими, когда они обнимают меня за плечи',
        }],
        [{
          t: 'A',
          a: 'Мне нравится, когда другие хвалят меня за мои успехи',
        }, {
          t: 'D',
          a: 'Когда человек делает ради меня то, что ему самому не нравится, значит, он любит меня',
        }],
        [{
          t: 'D',
          a: 'Мне нравится, когда друзья или близкие, проходя мимо, хлопают меня по плечу',
        }, {
          t: 'C',
          a: 'Мне нравится, когда меня внимательно слушают и проявляют искренний интерес к моим словам',
        }],
        [{
          t: 'D',
          a: 'Я чувствую себя любимым, когда друзья или близкие помогают мне в работе или осуществление каких-то планов',
        }, {
          t: 'C',
          a: 'Мне очень нравится принимать и дарить подарки друзьям и близким',
        }],
        [{
          t: 'A',
          a: 'Мне нравится, когда кто-то делает мне комплименты по поводу моей внешности',
        }, {
          t: 'B',
          a: 'Я чувствую себя любимым, когда кто-то старается понять мои чувства',
        }],
        [{
          t: 'E',
          a: 'Я чувствую себя уверенно, когда дорогие мне люди дружески прикасаются ко мне',
        }, {
          t: 'D',
          a: 'Я чувствуют себя любимым, когда мне помогают',
        }],
        [{
          t: 'D',
          a: 'Я ценю, когда дорогие мне люди что-то делают для меня. Их дела – лучший знак их любви',
        }, {
          t: 'C',
          a: 'Мне нравится принимать подарки от дорогих мне людей – это маленькие напоминания их любви',
        }],
        [{
          t: 'B',
          a: 'Мне нравится, когда кто-то уделяет мне безраздельное, личное, внимание',
        }, {
          t: 'D',
          a: 'Мне нравится, когда мне оказывают помощь',
        }],
        [{
          t: 'C',
          a: 'Я чувствую себя любимым, когда мне на День Рождения дарят подарки',
        }, {
          t: 'A',
          a: 'Я чувствую себя любимым, когда мне говорят на День Рождения теплые слова',
        }],
        [{
          t: 'C',
          a: 'Если кто-то дарит мне подарок, значит, он думает обо мне',
        }, {
          t: 'D',
          a: 'Я чувствую себя любимым, когда кто-то помогает мне по хозяйству',
        }],
        [{
          t: 'B',
          a: 'Я ценю, когда кто-то внимательно меня слушает и не перебивает',
        }, {
          t: 'C',
          a: 'Я ценю, когда кто-то дарит мне подарки на разные праздники или даже без повода',
        }],
        [{
          t: 'D',
          a: 'Мне приятно, когда близкие заботятся обо мне, взяв на себя часть моих ежедневных обязанностей',
        }, {
          t: 'B',
          a: 'Мне нравится путешествовать с дорогими мне людьми',
        }],
        [{
          t: 'E',
          a: 'Я люблю целовать тех, кто близок мне, и принимать от них поцелуи',
        }, {
          t: 'C',
          a: 'Я прихожу в вострог, когда кто-то дарит мне подарки без всякого повода',
        }],
        [{
          t: 'A',
          a: 'Мне нравится, когда мне говорят, что ценят меня',
        }, {
          t: 'B',
          a: 'Мне нравится, когда мой собеседник смотрит мне в глаза',
        }],
        [{
          t: 'C',
          a: 'Подарки от друга или любимого всегда ценны и дороги для меня',
        }, {
          t: 'E',
          a: 'Мне приятно, когда друг или любимый касается меня',
        }],
        [{
          t: 'D',
          a: 'Я чувствую себя любимым, когда кто-то с удовольствием выполняет мою просьбу',
        }, {
          t: 'A',
          a: 'Я чувствую себя любимым, когда кто-то говорит мне, как я ему дорог',
        }],
        [{
          t: 'E',
          a: 'Я нуждаюсь в дружеских прикосновениях каждый день',
        }, {
          t: 'A',
          a: 'Я нуждаюсь в словах поощрения каждый день',
        }],
      ],
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      img7,
      img8,
      img9,
      img10,
      img11,
      img12,
      img13,
      img14,
      img15,
      img16,
      img17,
      img18,
      img19,
      img20,
      img21,
      img22,
      img23,
      img24,
      img25,
      img26,
      img27,
      img28,
      img29,
      img30,
      a: [],
      b: [],
      c: [],
      d: [],
      e: [],
    };
  },
  methods: {
    next() {
      this[this.answer[this.current - 1].toLowerCase()].push(1);
      this.current += 1;
      if (this.current > 30) {
        this.$router.push(`/result/${this.type}?a=${this.a.length}&b=${this.b.length}&c=${this.c.length}&d=${this.d.length}&e=${this.e.length}`);
      }
    },
  },
  computed: {
    lineStyle() {
      let width = {
        1: '.5%',
        2: '3.33%',
        3: '6.26%',
        4: '9.33%',
        5: '12.33%',
        6: '15.33%',
        7: '18.33%',
        8: '21.33%',
        9: '24.33%',
        10: '27.33%',
        11: '31%',
        12: '34.3%',
        13: '38%',
        14: '41.5%',
        15: '45%',
        16: '48.5%',
        17: '52.2%',
        18: '56%',
        19: '59.5%',
        20: '63%',
        21: '66.5%',
        22: '70%',
        23: '74%',
        24: '77.5%',
        25: '81%',
        26: '85%',
        27: '88%',
        28: '92%',
        29: '96%',
        30: '100%',
      };
      if (window.innerWidth < 768) {
        width = {
          1: '.5%',
          2: '3.33%',
          3: '6.26%',
          4: '9.33%',
          5: '11.33%',
          6: '13.33%',
          7: '16.33%',
          8: '18.33%',
          9: '23.33%',
          10: '24.33%',
          11: '27.33%',
          12: '31.3%',
          13: '35.33%',
          14: '38.33%',
          15: '42.33%',
          16: '45.33%',
          17: '49.33%',
          18: '53.33%',
          19: '57.33%',
          20: '60.33%',
          21: '64.33%',
          22: '68.33%',
          23: '72.33%',
          24: '75.33%',
          25: '79.33%',
          26: '83.33%',
          27: '87.33%',
          28: '91.33%',
          29: '95.33%',
          30: '100%',
        };
      }
      return {
        width: width[this.current],
      };
    },
    type() {
      return this.$route.params.type;
    },
    img() {
      return `${this[`img${this.current}`]}`;
    },
    answers() {
      return this.questions[this.current - 1];
    },
  },
};
</script>
